// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-note-page-query-js": () => import("./../../../src/templates/note-page-query.js" /* webpackChunkName: "component---src-templates-note-page-query-js" */),
  "component---src-templates-notes-page-query-js": () => import("./../../../src/templates/notes-page-query.js" /* webpackChunkName: "component---src-templates-notes-page-query-js" */),
  "component---src-templates-notes-tag-page-query-js": () => import("./../../../src/templates/notes-tag-page-query.js" /* webpackChunkName: "component---src-templates-notes-tag-page-query-js" */),
  "component---src-templates-notes-tags-page-query-js": () => import("./../../../src/templates/notes-tags-page-query.js" /* webpackChunkName: "component---src-templates-notes-tags-page-query-js" */),
  "component---src-templates-stories-page-query-js": () => import("./../../../src/templates/stories-page-query.js" /* webpackChunkName: "component---src-templates-stories-page-query-js" */),
  "component---src-templates-story-page-query-js": () => import("./../../../src/templates/story-page-query.js" /* webpackChunkName: "component---src-templates-story-page-query-js" */)
}

